<template>
  <CommonLayout class="catalog-layout">
    <div class="catalog">
      <div class="catalog__container">
        <div class="catalog__header">
          <div class="catalog__group">
            <h1 class="title">Журнал уведомлений</h1>
          </div>
        </div>
        <div class="col__filter notify-read-all__wrapper">
          <button
            v-show="totalUnread"
            class="button button-default notify-read-all"
            :disabled="readAllStatus === 'loading'"
            @click="readNotifyAll()"
          >
            Прочитать все
          </button>
          <div class="col col__filter-text">
            <div class="text">Фильтр по событиям:</div>
          </div>
          <div class="col col__filter-type">
            <v-select
              class="select-custom"
              v-model="value"
              :clearable="false"
              :searchable="false"
              :options="optionsType"
            ></v-select>
          </div>
        </div>

        <table v-if="filteredList.length" class="table">
          <tr
            class="table-divider"
            v-for="(item, index) in filteredList"
            :key="index"
            :class="{ new: !item.isAccepted }"
            @click="onClick(item)"
          >
            <td width="70%" class="text text__text-table-message">
              <div
                class="notify-title"
                :title="item.notificationType | titleFilter"
              >
                {{ item.notificationType | titleFilter }}
              </div>
            </td>
            <td width="30%" class="table-data text">
              {{ item.notificationType | notificationTypeFilter }}
            </td>
            <td class="table-data table-data__right-padding text">
              {{ item.dateTime | formatDateTime }}
            </td>
          </tr>
        </table>

        <table v-else class="table">
          <tr style="cursor: default">
            <td style="padding-left: 0" class="text text__text-table-message">
              Пусто
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="modals">
      <ModalTaskQuestion />
      <ModalTaskTest />
      <ModalTaskKPI />
      <ModalTaskBusiness />
    </div>
  </CommonLayout>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { isJSON, formatDateTime } from "@/utils";
import {
  GetOwnNotifications,
  MarkNotification,
  MarkAllNotifications,
} from "@/api/PlayerNotifications";
import { GetQuestByID } from "@/api/PlayerQuests";
import { GetProgressMechanic } from "@/api/ProgressMechanics";
import { GetGratitudeById } from "@/api/Gratitudes";
import CommonLayout from "@/layout/CommonLayout.vue";
import { islandList } from "@/data/game";
import ModalTaskKPI from "@/components/ModalTaskKPI.vue";
import ModalTaskBusiness from "@/components/ModalTaskBusiness.vue";
import ModalTaskQuestion from "@/components/ModalTaskQuestion.vue";
import ModalTaskTest from "@/components/ModalTaskTest.vue";

const optionsTitle = [
  // StartOfNewWeek,
  "Неделя началась! Старейшины Обливии сообщают, что пришло время отправляться на поиски камней величия и получать GPB-coins!",
  // NewIslandOpened,
  "Высаживаемся! Планета открыта! Ищите новые задания и зарабатывайте GPB-coins!",
  // LastWeekOfGamePeriod,
  "Готовим двигатели Фалкона к запуску: до конца миссии осталась всего одна неделя! Торопитесь!",
  // GameStart,
  "Все на корабль! Ваше космическое приключение начинается! Полетели!",
  // GameEnd,
  "Дефендеры! Кранос повержен! Вселенная вздохнула с облегчением. Игра завершена!",
  // StoreNewOrder,
  "Поздравляем, у вашего заказа теперь есть номер.",
  // StoreOrderChanged,
  "Обратите внимание: статус вашего заказа изменился.",
  // AdministratorManual,
  "Почтовый дрон доставил вам послание! Вы получили сообщение от администратора игры.",
  // ProgressMechanicApplied,
  "Поиск камня пойдет быстрее! Для вашей команды активирована механика ускорения.",
  // GratitudeSent,
  "Получилось! Почтовый дрон с благодарностью отправлен другому дефендеру.",
  // GratitudeReceived,
  "Почтовый дрон доставил благодарность от другого дефендера.",
  // NewQuestsAvailable,
  "Черная дыра меня затяни! Вам наконец доступно новое задание и новый шанс заработать GPB-coins!",
  // QuestReceived,
  "Дефендер! Нас снова ждет великая миссия, да и камни сами себя не добудут! Поступило новое задание!",
  // QuestCompleted,
  "Великая цель на шаг ближе! А все потому, что вам удалось успешно выполнить задание.",
  // CaptainsQuestReceived
  "Смирно! Вы получили задание от капитана команды.",
  // QuestOnModeration
  "Вы приняли супергеройское задание! Галактический совет сообщит вам о результатах его выполнения, следите за уведомлениями!",
  // QuestFailed,
  "Увы, звезды не на вашей стороне!",
  // GratitudeSeasonStarted
  "Новые благодарности начислены!",
  // TeamQuestAppointed
  "Командная цель назначена",
  // TeamQuestSucceeded
  "Командная цель завершена",
  // TeamQuestFailed
  "Командная цель завершена",
  // Achievement
  "Вы получили новую награду! Для просмотра перейдите в раздел с достижениями ",
  // NewIdeaSeason
  `У идей начался новый этап. Нажми "Перейти", чтобы узнать подробности`,
  // IdeaStateChanged
  "По твоей идее пришел ответ модератора! Перейди и посмотри детали ",
  // BattleApplicationStarted,
  "Начался сбор заявок на участие в новом поединке! Чтобы участвовать, подай заявку.",
  // BattleProcessStarted,
  "Поединок начался! Приложи максимум усилий, чтобы победить соперника!",
  // BattleCompleted
  "Доступны результаты поединка!",
];

const optionsType = [
  { code: "0", label: "Все" },
  { code: "1", label: "Системное" },
  { code: "2", label: "Событие Виртуального магазина" },
  { code: "3", label: "Сообщение от администратора" },
  { code: "4", label: "Благодарность" },
  { code: "5", label: "Задание" },
  { code: "6", label: "Достижение" },
  { code: "7", label: "Идеи" },
  { code: "8", label: "Поединок" },
];

const questName = (type) => {
  let result = "";
  switch (type) {
    case 0: // KPI
      result = "KPI";
      break;
    case 1: // тест
      result = "Тест";
      break;
    case 2: // вопрос
      result = "Вопрос";
      break;
    case 3: // off-game
      result = "Off-game";
      break;
  }
  return result;
};

// const questScore = (score) => {
//   let result = "";
//   switch (score) {
//     case 5:
//       result = "Отлично!";
//       break;
//     case 4:
//       result = "Хорошо!";
//       break;
//     case 3:
//       result = "Нормально!";
//       break;
//   }
//   return result;
// };

function getGroupType(notificationType) {
  let value = null;

  // 1 Системное
  // 2 События Виртуального магазина
  // 3 Сообщение от администратора
  // 4 Благодарности
  // 5 Задания
  // 6 Ачивки
  // 7 Идеи
  // 8 Поединок
  switch (notificationType) {
    case 0: // StartOfNewWeek
    case 1: // NewIslandOpened
    case 2: // LastWeekOfGamePeriod
    case 3: // GameStart
    case 4: // GameEnd
      value = 1;
      break;
    case 5: // StoreNewOrder
    case 6: // StoreOrderChanged
      value = 2;
      break;

    case 7: // AdministratorManual
    case 8: // ProgressMechanicApplied
      value = 3;
      break;

    case 9: // GratitudeSent
    case 10: // GratitudeReceived
    case 17: // GratitudeSeasonStarted
      value = 4;
      break;

    case 11: // NewQuestsAvailable
    case 12: // QuestReceived
    case 13: // QuestCompleted
    case 14: // CaptainsQuestReceived
    case 15: // QuestOnModeration
    case 16: // QuestFailed
    case 18: // TeamQuestAppointed
    case 19: // TeamQuestSucceeded
    case 20: // TeamQuestFailed
      value = 5;
      break;

    case 21: // Achievement
      value = 6;
      break;

    case 22: // NewIdeaSeason
    case 23: // IdeaStateChanged
      value = 7;
      break;

    case 24: // BattleApplicationStarted,
    case 25: // BattleProcessStarted,
    case 26: // BattleCompleted
      value = 8;
      break;
  }
  return value;
}

export default {
  components: {
    CommonLayout,
    ModalTaskKPI,
    ModalTaskBusiness,
    ModalTaskQuestion,
    ModalTaskTest,
  },
  filters: {
    notificationTypeFilter(type) {
      const number = getGroupType(type);
      return optionsType[number]?.label;
    },
    titleFilter(number) {
      return optionsTitle[number];
    },
  },
  computed: {
    ...mapGetters(["getProfile"]),
    filteredList() {
      if (this.value.code > 0) {
        return this.list.filter((item) => {
          return (
            getGroupType(item.notificationType) === Number(this.value.code)
          );
        });
      } else {
        return this.list;
      }
    },
    totalUnread() {
      return this.list.reduce(
        (acc, item) => (item?.isAccepted ? acc : ++acc),
        0
      );
    },
  },
  data() {
    return {
      optionsType,
      value: null,
      readAllStatus: null,
      list: [],
    };
  },
  created() {
    this.value = this.optionsType[0];
    this.USER_FETCH_NOTIFY();
    this.fetchData();
  },
  methods: {
    ...mapActions(["USER_FETCH_NOTIFY"]),
    fetchData() {
      GetOwnNotifications().then((resp) => {
        this.list = resp.data;
      });
    },
    readNotify(id) {
      MarkNotification({ notificationId: id }).then(() => {
        this.USER_FETCH_NOTIFY();
        this.fetchData();
      });
    },
    readNotifyAll() {
      this.readAllStatus = "loading";
      MarkAllNotifications()
        .then(() => {
          this.readAllStatus = null;
          this.USER_FETCH_NOTIFY();
          this.fetchData();
        })
        .catch(() => {
          this.readAllStatus = null;
        });
    },
    onClick(data) {
      const type = data.notificationType;
      const number = getGroupType(type);

      this.readNotify(data.id);

      const date = formatDateTime(data.dateTime);

      const templateQuestInfo = `<table>
        <tr>
          <td>${date}</td>
          <td>Неделя получения задания: ${data.weekIndex + 1}</td>
        </tr>
        <tr>
          <td>Тип задания: ${questName(data.questType)}</td>
          <td>Название задания: ${data.questTitle}</td>
        </tr>
      </table>`;

      // const templateQuestResult = questScore(data.questScore)
      //   ? `<table>
      //   <tr>
      //     <td>Оценка: ${questScore(data.questScore)}!</td>
      //     <td>Награда: ${data.questScore} ${this.COINS}</td>
      //   </tr>
      // </table>`
      //   : `Награда: ${data.questScore} ${this.COINS}`;

      switch (number) {
        case 1: // Системное
          switch (type) {
            case 0: // StartOfNewWeek
              this.$modal.show("message", {
                title: "Системное уведомление",
                date: date,
                message:
                  "Неделя началась! Старейшины Обливии сообщают, что пришло время отправляться на поиски камней величия и получать GPB-coins!",
                textButton: "Перейти",
                cb: () => {
                  if (data.islandIndex >= 0) {
                    islandList.forEach((island, index) => {
                      if (index === data.islandIndex) {
                        this.$router.push({
                          name: "island",
                          params: { name: island.name },
                        });
                        return;
                      }
                    });
                  }
                },
              });
              break;
            case 1: // NewIslandOpened
              this.$modal.show("message", {
                title: "Системное уведомление",
                date: date,
                message:
                  "Высаживаемся! Планета открыта! Ищите новые задания и зарабатывайте GPB-coins!",
                textButton: "Перейти",
                cb: () => {
                  if (data.islandIndex >= 0) {
                    islandList.forEach((island, index) => {
                      if (index === data.islandIndex) {
                        this.$router.push({
                          name: "island",
                          params: { name: island.name },
                        });
                        return;
                      }
                    });
                  }
                },
              });
              break;
            case 2: // LastWeekOfGamePeriod
              this.$modal.show("message", {
                title: "Системное уведомление",
                date: date,
                message:
                  "Готовим двигатели Фалкона к запуску: до конца миссии осталась всего одна неделя! Торопитесь!",
              });
              break;
            case 3: // GameStart
              this.$modal.show("message", {
                title: "Системное уведомление",
                date: date,
                message:
                  "Все на корабль! Ваше космическое приключение начинается! Полетели!",
                textButton: "Вперед!",
                cb: () => {
                  this.$router.push({ name: "home" });
                },
              });
              break;
            case 4: // GameEnd
              this.$modal.show("message", {
                title: "Системное уведомление",
                date: date,
                message:
                  "Дефендеры! Кранос повержен! Вселенная вздохнула с облегчением. Игра завершена!",
              });
              break;

            case 18: // TeamQuestAppointed
            case 19: // TeamQuestSucceeded
            case 20: // TeamQuestFailed
              this.$modal.show("message", {
                title: "Сообщение от Старейшин",
                quotes: true,
                date: date,
                message: data.administratorMessage,
              });
              break;
          }
          break;
        case 2: // Событие Виртуального магазина
          this.$router.push({ name: "orders" });
          break;
        case 3: // Сообщение от администратора
          switch (type) {
            case 7: // AdministratorManual
              if (isJSON(data.administratorMessage)) {
                const gratitude = JSON.parse(data.administratorMessage);

                gratitude.time = data.dateTime;
                gratitude.sender = gratitude.Sender;
                gratitude.receiver = gratitude.Receiver;
                gratitude.message = gratitude.Message;
                gratitude.coins = gratitude.Coins;

                this.$modal.show("gratitude", {
                  type: 0,
                  data: gratitude,
                });
              } else {
                this.$modal.show("messageLarge", {
                  title: "Сообщение от Старейшин",
                  quotes: true,
                  date: date,
                  message: data.administratorMessage,
                });
              }
              break;
            case 8: // ProgressMechanicApplied
              GetProgressMechanic(data.progressMechanicId).then((resp) => {
                this.$modal.show("mechanic", resp.data);
              });
              break;
          }
          break;
        case 4: // Благодарность
          switch (type) {
            case 9: // GratitudeSent
            case 10: // GratitudeReceived
              GetGratitudeById(data.gratitudeId).then((resp) => {
                this.$modal.show("gratitude", {
                  type: type === 9 ? 1 : 0,
                  data: resp.data,
                });
              });
              break;
            case 17: // GratitudeSeasonStarted
              this.$modal.show("message", {
                title: "Новые благодарности начислены!",
                date: date,
                message: data.administratorMessage,
              });
              break;
          }
          break;
        case 5: // Задание
          switch (type) {
            case 11: // NewQuestsAvailable
              this.$modal.show("message", {
                title: "Уведомление о задании",
                date: date,
                message:
                  "Черная дыра меня затяни! Вам наконец доступно новое задание и новый шанс заработать GPB-coins!",
                textButton: "Перейти",
                cb: () => {
                  if (data.islandIndex >= 0) {
                    islandList.forEach((island, index) => {
                      if (index === data.islandIndex) {
                        this.$router.push({
                          name: "island",
                          params: { name: island.name },
                        });
                        return;
                      }
                    });
                  }
                },
              });
              break;
            case 12: // QuestReceived
              this.$modal.show("message", {
                title: "Уведомление о задании",
                date: date,
                message:
                  "Дефендер! Нас снова ждет великая миссия, да и камни сами себя не добудут! Поступило новое задание!",
                textButton: "Перейти",
                cb: () => {
                  if (data.questId) {
                    this.openQuest(data.questId);
                  }
                },
              });
              break;
            case 13: // QuestCompleted
              this.$modal.show("message", {
                title: "Уведомление о задании",
                desc: templateQuestInfo,
                // message: data.administratorMessage
                //   ? data.administratorMessage
                //   : null,
                messageReward: data.administratorMessage
                  ? data.administratorMessage
                  : null,
                reward: data.questCoins,
              });
              break;
            case 14: // CaptainsQuestReceived
              this.$modal.show("message", {
                title: "Уведомление о задании",
                date: date,
                message: "Смирно! Вы получили задание от капитана команды .",
                textButton: "Перейти",
                cb: () => {
                  if (data.islandIndex >= 0) {
                    islandList.forEach((island, index) => {
                      if (index === data.islandIndex) {
                        this.$router.push({
                          name: "island",
                          params: { name: island.name },
                        });
                        return;
                      }
                    });
                  }
                },
              });
              break;
            case 15: // QuestOnModeration
              this.$modal.show("message", {
                title: "Уведомление о задании",
                desc: templateQuestInfo,
                message:
                  "Вы приняли супергеройское задание! Галактический совет сообщит вам о результатах его выполнения, следите за уведомлениями!",
              });
              break;
            case 16: // QuestFailed
              if (data.administratorMessage) {
                this.$modal.show("messageLarge", {
                  title: "Уведомление о задании",
                  desc: templateQuestInfo,
                  message: data.administratorMessage,
                  // caption: templateQuestResult,
                });
              } else {
                this.$modal.show("message", {
                  title: "Уведомление о задании",
                  desc: templateQuestInfo,
                  message: "Увы, звезды не на вашей стороне!",
                  // caption: templateQuestResult,
                });
              }
              break;
            case 18: // TeamQuestAppointed
              if (data.administratorMessage) {
                this.$modal.show("messageLarge", {
                  title: "Командная цель назначена",
                  // desc: templateQuestInfo,
                  textButton: "Перейти",
                  cb: () => {
                    this.$router.push({ name: "teamGoal" });
                  },
                  message: data.administratorMessage,
                });
              } else {
                this.$modal.show("message", {
                  title: "Командная цель назначена",
                  // desc: templateQuestInfo,
                  textButton: "Перейти",
                  cb: () => {
                    this.$router.push({ name: "teamGoal" });
                  },
                  message:
                    "Тебе начислены новые благодарности для отправки другим игрокам",
                });
              }
              break;
            case 19: // TeamQuestSucceeded
              if (data.administratorMessage) {
                this.$modal.show("messageLarge", {
                  title: "Командная цель завершена",
                  // desc: templateQuestInfo,
                  textButton: "Перейти",
                  cb: () => {
                    this.$router.push({ name: "teamGoal" });
                  },
                  message: data.administratorMessage,
                });
              } else {
                this.$modal.show("message", {
                  title: "Командная цель завершена",
                  // desc: templateQuestInfo,
                  textButton: "Перейти",
                  cb: () => {
                    this.$router.push({ name: "teamGoal" });
                  },
                  message: "Твоей команде назначена новая командная цель",
                });
              }
              break;
            case 20: // TeamQuestFailed
              if (data.administratorMessage) {
                this.$modal.show("messageLarge", {
                  title: "Командная цель завершена",
                  // desc: templateQuestInfo,
                  textButton: "Перейти",
                  cb: () => {
                    this.$router.push({ name: "teamGoal" });
                  },
                  message: data.administratorMessage,
                });
              } else {
                this.$modal.show("message", {
                  title: "Командная цель завершена",
                  // desc: templateQuestInfo,
                  textButton: "Перейти",
                  cb: () => {
                    this.$router.push({ name: "teamGoal" });
                  },
                  message:
                    "Очень жаль! Твоя команда не смогла выполнить цель:(",
                });
              }
              break;
          }
          break;
        case 6: // Achivements
          switch (type) {
            case 21: // Achievement
              this.$modal.show("message", {
                title: "Достижение",
                date: date,
                message:
                  "Вы получили новую награду! Для просмотра перейдите в раздел с достижениями",
                textButton: "Перейти",
                cb: () => {
                  this.$router.push(
                    `/achievements/${this.getProfile.id}/?achievementId=${data.achievementId}&achievementLevel=${data.achievementLevel}`
                  );
                },
              });
              break;
          }
          break;
        case 7: // Ideas
          switch (type) {
            case 22: // NewIdeaSeason
            case 23: // IdeaStateChanged
              this.$modal.show("message", {
                date: date,
                message: data.administratorMessage,
                textButton: "Перейти",
                cb: () => {
                  this.$router.push({
                    name: "ideas",
                  });
                },
              });
              break;
          }
          break;
        case 8: // Battles
          switch (type) {
            case 24: // Наступление этапа «Сбор заявок»
              this.$modal.show("message", {
                date: date,
                message:
                  "Начался сбор заявок на участие в новом поединке! Чтобы участвовать, подай заявку.",
                textButton: "Перейти",
                cb: () => {
                  this.$router.push({
                    name: "battles",
                  });
                },
              });
              break;
            case 25: // Наступление этапа «Поединок»
              this.$modal.show("message", {
                date: date,
                message:
                  "Поединок начался! Приложи максимум усилий, чтобы победить соперника!",
                textButton: "Перейти",
                cb: () => {
                  this.$router.push({
                    name: "battles",
                  });
                },
              });
              break;
            case 26: // Результаты поединка загружены (Загрузка и расчет результатов поединка завершены)
              this.$modal.show("message", {
                date: date,
                message: "Доступны результаты поединка!",
                textButton: "Перейти",
                cb: () => {
                  this.$router.push({
                    name: "battlesHistory",
                  });
                },
              });
              break;
          }
          break;
      }
    },
    openQuest(questId) {
      GetQuestByID({ questId: questId }).then((resp) => {
        const questType = resp.data.questType;

        switch (questType) {
          case 0: // Kpi
            this.$modal.show("kpi", {
              data: resp.data,
              type: "kpi",
              hideClose: true,
            });
            break;
          case 1: // Test
            this.$modal.show("test", { questId: resp.data.id });
            break;
          case 2: // Question
            this.$modal.show("question", { questId: resp.data.id });
            break;
          case 3: // OffGame
            this.$modal.show("kpi", {
              data: resp.data,
              type: "offGame",
              hideClose: true,
            });
            break;
          case 4: // Business
            this.$modal.show("business", { data: resp.data, hideClose: true });
            break;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.notify-read-all {
  &__wrapper {
    position: relative;
  }
  position: absolute;
  bottom: 100%;
  right: 0;
  margin-bottom: 16px;
}
.table {
  width: 1230px;
  border-collapse: collapse;
  tr {
    cursor: pointer;
    &.new {
      position: relative;
      background-color: rgba(0, 255, 254, 0.15);
      .text__text-table-message {
        // position: relative;
        &::before {
          position: absolute;
          top: 0;
          left: 0;
          background-image: url("data:image/svg+xml,%3Csvg width='8' height='64' viewBox='0 0 8 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 64h8v-3H2.7603V3H8V0H0v64z' fill='%230FF'/%3E%3C/svg%3E");
          width: 8px;
          height: 100%;
          background-size: contain;
          background-repeat: no-repeat;
          content: "";
        }
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          background-image: url("data:image/svg+xml,%3Csvg width='8' height='64' viewBox='0 0 8 64' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8 0H0v3h5.2397v58H0v3h8V0z' fill='%230FF'/%3E%3C/svg%3E");
          width: 8px;
          height: 100%;
          background-size: contain;
          background-repeat: no-repeat;
          content: "";
        }
      }
    }
  }
}
.table-data {
  padding-left: 20px;
  white-space: nowrap;
  &__right-padding {
    padding-right: 30px;
  }
}
.table-divider {
  background: url("~@/assets/img/pic2/divider-table.svg") 0 100% no-repeat;
}
.header {
  font-weight: normal;
  font-size: 54px;
  color: #5cffed;
}
.container {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  &__table {
    width: 100%;
  }
  &__skeleton {
    width: 322px;
    margin-top: 20%;
    margin-left: 66px;
    margin-bottom: 30px;
    opacity: 0.5;
  }
}
.row {
  position: relative;
  width: 100%;
}
.col {
  display: inline-flex;
  &__filter {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 20px;
  }
  &__filter-text {
    text-align: right;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;

    color: #ade5d9;
  }
  &__filter-type {
    margin-left: 10px;
  }
}
.text {
  font-family: $fontRafale;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  &__text-admin {
    text-decoration-line: underline;
  }
  &__text-table-message {
    font-family: $fontRafale;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    padding: 10px 0 15px 30px;
  }
}

.notify-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 520px;
}
</style>
